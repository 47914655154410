import {
    Alert,
    Badge,
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Form,
    Modal,
    Navbar,
    NavDropdown,
    Row
} from "react-bootstrap";
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import {Arrow90degLeft, ArrowLeft, ArrowRepeat, ArrowUpCircle, ExclamationOctagon, House} from "react-bootstrap-icons";
import {QueueStatus} from "../components/utils/QueueStatus";
import {formatDateTime} from "../components/utils/Utils";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {toast, ToastContainer} from "react-toastify";
import {useAccessToken} from "../components/useAccessToken";
import {useQueueApiHeaders} from "../components/MerchantController";
import useQueueApiClient from "../components/useQueueApiClient";

const animatedComponents = makeAnimated();

const MerchantQueueFilter = (props) => {
    const options = [
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5+'}
    ]

    const [inQueueFilter, setInQueueFilter] = useState(true)
    const [waitingConfirmationFilter, setWaitingConfirmationFilter] = useState(true)
    const [calledFilter, setCalledFilter] = useState(true)
    const [selectedOptions, setSelectedOptions] = useState(options.map(o => o.value))
    const [filterData, setFilterData] = useState({
        status: {
            inQueue: inQueueFilter,
            waitingConfirmation: waitingConfirmationFilter
        },
        queueSlots: selectedOptions
    })

    const fetch = () => {
        props.fetchFunction(filterData)
    }

    useEffect(() => {
        setFilterData({
            status: {
                inQueue: inQueueFilter,
                waitingConfirmation: waitingConfirmationFilter,
                called: calledFilter
            },
            queueSlots: selectedOptions
        })
    }, [inQueueFilter, waitingConfirmationFilter, calledFilter, selectedOptions]);

    useEffect(() => {
        fetch()
    }, [filterData]);

    return (
        <Container>
            <Row sm={12} lg={8} md={10}>
                <Card>
                    <Card.Title className={'mt-2'}>Filtro</Card.Title>
                    <strong>Mesa para:</strong>
                    <Select closeMenuOnSelect={false} isMulti options={options}
                            onChange={newValue => setSelectedOptions(newValue.map(o => o.value))}
                            defaultValue={options}/>
                    <strong className={'mt-2'}>Status:</strong>
                    <Row className={'mb-3'}>
                        <Col className={'mx-auto'} sm={12} md={10} lg={8}>
                            <ButtonGroup>
                                <Button variant={inQueueFilter ? 'success' : 'outline-success'}
                                        key={`${Math.random()}:${inQueueFilter}`}
                                        onClick={() => setInQueueFilter(!inQueueFilter)}>Na fila</Button>

                                <Button variant={waitingConfirmationFilter ? 'warning' : 'outline-warning'}
                                        key={`${Math.random()}:${waitingConfirmationFilter}`}
                                        onClick={() => setWaitingConfirmationFilter(!waitingConfirmationFilter)}>Aguardando
                                    confirmação</Button>

                                <Button variant={calledFilter ? 'info' : 'outline-info'}
                                        key={`${Math.random()}:${calledFilter}`}
                                        onClick={() => setCalledFilter(!calledFilter)}>Chamados</Button>
                            </ButtonGroup>
                        </Col>

                        <Col sm={12} md={10} lg={8} className={'mt-3 mx-auto'}>
                                <Button variant={'light'}
                                        key={`${Math.random()}`}
                                        onClick={fetch}><ArrowRepeat/> Recarregar</Button>
                        </Col>
                    </Row>
                </Card>
            </Row>
        </Container>
    )
}

const MerchantQueue = () => {
    const [fetchingQueue, setFetchingQueue] = useState(true)
    const [filterState, setFilterState] = useState({})
    const [queue, setQueue] = useState([])
    const [modalType, setModalType] = useState('')
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate()
    const [reason, setReason] = useState('')
    const [contextQueue, setContextQueue] = useState({})
    const [cookies, removeCookies] = useCookies(['email', 'access_token', 'merchant_id'])
    const { bearerToken, cleanToken } = useAccessToken();
    const { queueApiClient } = useQueueApiClient()

    const fetchQueue = (filter) => {
        let status = []
        setFilterState(filter)
        if (filter.status.inQueue)
            status.push('IN_QUEUE')

        if (filter.status.waitingConfirmation)
            status.push('WAITING_CONFIRMATION')

        if (filter.status.called)
            status.push('CALLED')

        if (filter.queueSlots.length > 0) {
            axios.get(`${process.env.REACT_APP_SERVICE_URL}/queue/current`, {
                headers: {
                    "Authorization": bearerToken
                },
                params: {
                    merchantId: cookies.merchant_id,
                    status: status.join(","),
                    slots: filter.queueSlots.join(",")
                }
            }).then(response => {
                setQueue(response.data)
                setFetchingQueue(false)
                document.title = `Fila (${queue.length})`
            }).catch(err => {
                toast.error("Erro ao buscar clientes", {
                    theme: "colored"
                })
            })
        }
    }
    const getQueueStatusDetails = (status) => {
        return QueueStatus[status]
    }
    const callConsumer = (queueId) => {
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/queue/call`, {}, {
            headers: {
                Authorization: bearerToken
            },
            params: {
                queueId: queueId
            }
        }).then(response => {
                toast.info('Usuario chamado', {
                    theme: "colored"
                })
                fetchQueue(filterState)
            }
        ).catch(err => {
            toast.error("Erro ao chamar cliente", {
                theme: "colored"
            })
        })
    };
    const acceptConsumer = (queueId) => {
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/queue/accept`, {}, {
            headers: {
                Authorization: bearerToken
            },
            params: {
                queueId: queueId
            }
        }).then(response => {
            toast.success('Usuario aceito', {
                theme: "colored"
            })
            fetchQueue(filterState)
        }).catch(err => {
            toast.error("Erro ao aceitar o cliente", {
                theme: "colored"
            })
        })
    };
    const removeConsumer = (queueId) => {
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/queue/remove`, {}, {
            headers: {
                Authorization: bearerToken
            },
            params: {
                queueId: queueId,
                reason: reason
            }
        }).then(response => {
            toast.success('Usuario removido', {
                theme: "colored"
            })
            setShowModal(false)
            setReason("")
            setContextQueue({})
            fetchQueue(filterState)
        }).catch(err => {
            toast.error("Erro ao remover o cliente", {
                theme: "colored"
            })
        })
    };
    const refuseConsumer = (queueId) => {
        queueApiClient.post(`/queue/refuse`, {}, {
            headers: {
                Authorization: bearerToken
            },
            params: {
                queueId: queueId,
                reason: reason
            }
        }).then(response => {
            toast.success('Usuario recusado', {
                theme: "colored"
            })
            fetchQueue(filterState)
        }).catch(err => {
            toast.error("Erro ao recusar o cliente", {
                theme: "colored"
            })
        })
    };
    const redirectToHome = () => {
        navigate('/merchant')
    }
    const checkInConsumer = (queueId) => {
        queueApiClient.post(`/queue/checkin`, {}, {
            params: {
                queueId: queueId
            }
        }).then(response => {
            toast.success("Check-in realizado", {
                theme: "colored"
            })
            fetchQueue(filterState)
        }).catch(err => {
            toast.error("Erro ao realizar checkin", {
                theme: "colored"
            })
        })
    };
    const openModalToRemoveConsumer = (queue) => {
        setReason("Não compareceu")
        setModalType('REMOVE')
        setContextQueue(queue)
        setShowModal(true)
    }
    const openModalToRefuseConsumer = (queue) => {
        setReason("Encerrando a operação")
        setContextQueue(queue)
        setShowModal(true)
    }

    const logout = () => {
        cleanToken()
        removeCookies('merchant_id')
        removeCookies('email')
        navigate("/login/merchant")
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchQueue(filterState)
        }, 15000)

        return () => clearInterval(intervalId);
    }, [filterState, queue])

    return (
        <Container>
            <Row>
                <Col sm={12} md={10} lg={8}
                     className={'mt-3 mx-auto text-center align-content-center align-items-center align-self-center justify-content-center'}>
                    <Navbar>
                        <Container>
                            <Navbar.Brand onClick={redirectToHome}>
                                <ArrowLeft/>
                                <img
                                    alt=""
                                    src="/kiwi-fruit.svg"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                />{' '}
                                Ki-wi</Navbar.Brand>
                            <Navbar.Toggle />
                            <Navbar.Collapse className="justify-content-end">
                                <NavDropdown title={cookies.email} id="basic-nav-dropdown">
                                    <NavDropdown.Item className={'text-danger'} onClick={logout}>
                                        Desconectar
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <MerchantQueueFilter fetchFunction={fetchQueue}/>
                    <hr/>
                    {fetchingQueue
                        ? (<Skeleton height={180} count={4}/>)
                        : (queue.length === 0 ? (
                            <Alert variant={'warning'}>
                                <ExclamationOctagon/> Fila vazia
                            </Alert>
                        ) : (
                            queue.map(q => {
                                let joinedAt = new Date(q.joinedAt)
                                const getQueueButtonsByStatus = (status) => {
                                    switch (status) {
                                        case 'IN_QUEUE': {
                                            return (
                                                <>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => callConsumer(q.id)}
                                                            variant={'success'}>Chamar</Button>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => openModalToRemoveConsumer(q)}
                                                            variant={'danger'}>Remover</Button>
                                                </>
                                            )
                                        }
                                        case 'WAITING_CONFIRMATION': {
                                            return (
                                                <>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => acceptConsumer(q.id)}
                                                            variant={'success'}>Aceitar</Button>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => openModalToRefuseConsumer(q)}
                                                            variant={'danger'}>Recusar</Button>
                                                </>
                                            )
                                        }
                                        case 'CALLED': {
                                            return (
                                                <>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => checkInConsumer(q.id)}
                                                            variant={'primary'}>Check-in</Button>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => openModalToRemoveConsumer(q)}
                                                            variant={'danger'}>Remover</Button>
                                                </>
                                            )
                                        }
                                        case 'ACCEPTED_RESTAURANT_CALL': {
                                            return (
                                                <>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => checkInConsumer(q.id)}
                                                            variant={'primary'}>Check-in</Button>
                                                    <Button size={'sm'} className={'mx-2'}
                                                            onClick={() => openModalToRemoveConsumer(q)}
                                                            variant={'danger'}>Remover</Button>
                                                </>
                                            )
                                        }
                                        default: {
                                            return (<></>)
                                        }
                                    }
                                };
                                return (
                                    <Card className={'mt-2'} border={getQueueStatusDetails(q.status).color}>
                                        <Card.Header>
                                            <strong className={'h2'}>{q.consumer.name}</strong><br/>
                                            <Row>
                                                <Col className={'mx-auto'} sm={8} md={8} lg={4}>
                                                    <Badge bg={getQueueStatusDetails(q.status).color}>
                                                        {getQueueStatusDetails(q.status).label}
                                                    </Badge>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            Mesa para <strong>{q.slot}</strong><br/>
                                            Na fila desde
                                            as <strong>{`${formatDateTime(joinedAt)}`}</strong><br/>
                                            Última atualização de status
                                            as <strong>{`${formatDateTime(new Date(q.lastStatusAt))}`}</strong><br/>
                                        </Card.Body>
                                        {getQueueStatusDetails(q.status).hasButtons ?
                                            <Card.Footer>
                                                {getQueueButtonsByStatus(q.status)}
                                            </Card.Footer> : <></>}
                                    </Card>
                                )
                            })
                        ))}
                < /Col>
            </Row>
            <Modal className={"text-center"} show={showModal} centered onHide={() => setShowModal(false)}>
                {modalType === 'REMOVE' ? (
                        <>
                            <Modal.Title className={'mt-2'}>Remover cliente da fila</Modal.Title>
                            <Modal.Body>
                                O cliente <strong>{contextQueue?.consumer?.name}</strong> sera removido.<br/>
                                Pode nos informar o motivo da remoção?
                                <Form.Select onChange={e => setReason(e.target.value)}>
                                    <option>Não compareceu</option>
                                    <option>Falta de insumos</option>
                                    <option>Problemas internos</option>
                                    <option>Outros</option>
                                </Form.Select>
                            </Modal.Body>
                            <Modal.Footer className={'justify-content-center'}>
                                <Button variant="danger" onClick={() => removeConsumer(contextQueue.id)}>
                                    Sim, remover cliente
                                </Button>
                                <Button variant="outline-success" onClick={() => setShowModal(false)}>
                                    Não, manter cliente
                                </Button>
                            </Modal.Footer>
                        </>
                    ) :
                    (
                        <>
                            <Modal.Title className={'mt-2'}>Recusar cliente</Modal.Title>
                            <Modal.Body>
                                O cliente <strong>{contextQueue?.consumer?.name}</strong> sera recusado.<br/>
                                Pode nos informar o motivo da recusa?
                                <Form.Select onChange={e => setReason(e.target.value)}>
                                    <option>Encerrando a operação</option>
                                    <option>Falta de insumos</option>
                                    <option>Problemas internos</option>
                                    <option>Outros</option>
                                </Form.Select>
                            </Modal.Body>
                            <Modal.Footer className={'justify-content-center'}>
                                <Button variant="danger" onClick={() => refuseConsumer(contextQueue.id)}>
                                    Sim, recusar cliente
                                </Button>
                                <Button variant="outline-success" onClick={() => setShowModal(false)}>
                                    Não, manter cliente
                                </Button>
                            </Modal.Footer>
                        </>
                    )}
            </Modal>
            <ToastContainer/>
        </Container>
    )
}

export default MerchantQueue