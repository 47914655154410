import axios from "axios";

const useQueueApiClient = () => {
    const queueApiClient = axios.create({
        baseURL: process.env.REACT_APP_SERVICE_URL,
        headers: {
            "Content-Type": "application/json"
        }
    });

    return {queueApiClient}
};

export default useQueueApiClient;