import {QRCode} from "react-qrcode-logo";
import {useCookies} from "react-cookie";
import {Button, Card, CardBody, CardFooter, CardHeader, Container, Form, Modal, Row} from "react-bootstrap";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const MerchantQRCode = (props) => {
    const [cookie] = useCookies(['merchant_id', 'access_token'])
    const [merchantData, setMerchantData] = useState()
    const [showModal, setShowModal] = useState(true)
    const navigate = useNavigate()

    const merchantId = cookie.merchant_id;

    const getAccessToken = () => {
        return `Bearer ${cookie.access_token}`
    }
    const fetchMerchantData = () => {
        axios.get(`${process.env.REACT_APP_SERVICE_URL}/merchant/full`, {
            headers: {
                Authorization: getAccessToken()
            },
            params: {
                id: merchantId
            }
        }).then(response => {
            setMerchantData(response.data)
        }).catch(err => {
            toast.error("Erro ao buscar dados do estabelecimento", {
                theme: "colored"
            })
        })
    }

    useEffect(() => {
        fetchMerchantData()
    }, []);

    const kiwiColor = {outer: '#5b3d2f', inner: '#04a733'}

    document.title = 'QRCode'
    return (
        <Container>
            <Container onClick={() => navigate('/merchant')} className={'h-100 d-flex justify-content-md-center align-items-center vh-100'}>
                <Card className={'mt-2'}>
                    <CardHeader className={'text-center'}>
                        <h1>Fila do {merchantData?.name}</h1>
                    </CardHeader>
                    <CardBody className={'center text-center'}>
                        <QRCode
                            size={300}
                            logoImage={'/kiwi-fruit.svg'}
                            fgColor={'#5b3d2f'}
                            qrStyle={'fluid'}
                            removeQrCodeBehindLogo={true}
                            logoPaddingStyle={'circle'}
                            eyeRadius={75}
                            eyeColor={[kiwiColor, kiwiColor, kiwiColor]}
                            value={`${process.env.REACT_APP_FRONT_URL}/queue/join?merchant=${merchantId}`}/>
                    </CardBody>
                    <CardFooter className={'text-center'}>
                        <p className={'text-muted mt-2'}>Aponte a câmera do seu celular para o QR Code, acesse o site, preencha o formulário e entre na fila.</p>
                    </CardFooter>
                </Card>
                <ToastContainer/>
            </Container>

            <Modal className={"text-center"} show={showModal} centered onHide={() => setShowModal(false)}>
                <Modal.Title className={'mt-2'}>Aviso</Modal.Title>
                <Modal.Body>
                    Nesta página, você tem acesso ao <strong>QR Code da fila do seu restaurante.</strong> Com este QR Code, os usuários podem entrar na fila facilmente.
                    Para retornar ao menu, basta clicar em qualquer lugar na página.
                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button variant="outline-success" onClick={() => setShowModal(false)}>
                        Entendi
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

export default MerchantQRCode
