import {Alert, Button, Card, CardBody, Col, Container, Form, Image, Modal, Row, Spinner} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {formatDateTime} from "../components/utils/Utils";
import {MDBContainer} from "mdb-react-ui-kit";
import {QueueStatus} from "../components/utils/QueueStatus";
import {toast, ToastContainer} from "react-toastify";
import {useCookies} from "react-cookie";
import Survey from "../components/Survey";
import useQueueApiClient from "../components/useQueueApiClient";

const MerchantCard = (props) => {
    const queue = props.queueData
    const isLast = props.last
    const refreshQueue = props.fetchQueueData
    const [showModal, setShowModal] = useState(false)
    const [reason, setReason] = useState("Tempo de espera")
    const [showSurvey, setShowSurvey] = useState(false)
    const {queueApiClient} = useQueueApiClient()
    const [canFeedback, setCanFeedback] = useState(false)

    const answerCall = () => {
        queueApiClient.post(`/queue/call/answer`, {}, {
            params: {
                queueId: queue.id,
                answer: true
            }
        }).then(response => {
            toast.success("Informamos ao estabelecimento que você está a caminho", {
                theme: "colored"
            })
            refreshQueue()
        }).catch(err => {
            toast.error("Erro ao responder chamado", {
                theme: "colored"
            })
        })
    }

    const checkIn = () => {
        queueApiClient.post(`/queue/checkin`, {}, {
            params: {
                queueId: queue.id
            }
        }).then(response => {
            toast.success("Check-in realizado", {
                theme: "colored"
            })
            refreshQueue()
        }).catch(err => {
            toast.error("Erro ao realizar checkin", {
                theme: "colored"
            })
        })
    }

    const quitQueue = () => {
        queueApiClient.post(`/queue/quit`, {}, {
            params: {
                queueId: queue.id,
                reason: reason
            }
        }).then(response => {
            setShowModal(false)
            refreshQueue()
        }).catch(err => {
            toast.error("Erro ao buscar clientes", {
                theme: "colored"
            })
        })
    }

    const getCardBodyByQueueStatus = (queue) => {
        const currentStatusDetail = QueueStatus[queue.status]
        return (
            <CardBody>
                <MDBContainer className="py-5 text-start">
                    <ul className="timeline-with-icons">
                        <li className={`timeline-item ${currentStatusDetail.color} mb-5`}>
                                    <span className="timeline-icon">
                                        {currentStatusDetail.icon}
                                    </span>

                            <h4 className="fw-bold">{currentStatusDetail.consumerLabel}</h4>
                            {isLast ? (
                                <p className={`text-muted fw-bold`}>{formatDateTime(new Date(queue.lastStatusAt))}</p>) : (<></>)}
                            {isLast
                                ? (
                                    <p>
                                        {queue.status === 'CHECK_IN' ? getContent(queue, true) : (<></>)}
                                        {currentStatusDetail.consumerContent}
                                        {currentStatusDetail.hasReason ? queue.reason : <></>}
                                    </p>
                                )
                                : getContent(queue, true)}
                        </li>
                        <hr/>
                        {queue.history.map(h => {
                            const statusDetails = QueueStatus[h.status]
                            return (
                                <>
                                    {h.status === 'WAITING_CONFIRMATION' ? (
                                            <li className="timeline-item">
                                                <h5 className="fw-bold">{statusDetails.consumerLabel}</h5>
                                                <p className={`text-muted fw-bold`}>{formatDateTime(new Date(h.createdAt))}</p>
                                                <span className="timeline-icon">
                                                    {statusDetails.icon}
                                                </span>
                                                <p className="text-muted">
                                                    O restaurante recebeu sua reserva.
                                                </p>
                                            </li>
                                        )
                                        : (
                                            <li className={`timeline-item ${statusDetails.color} mb-5`}>
                                                <span className={`timeline-icon`}>
                                                    {statusDetails.icon}
                                                </span>

                                                <h5 className="fw-bold">{statusDetails.consumerLabel}</h5>
                                                <p className={`text-muted fw-bold mb-2`}>{formatDateTime(new Date(h.createdAt))}</p>
                                                <p className="text-muted">
                                                    {statusDetails.consumerContent}
                                                </p>
                                            </li>
                                        )
                                    }
                                </>
                            )
                        })}
                    </ul>
                </MDBContainer>
            </CardBody>
        )
    }

    const getContent = (queue, currentStatus) => {
        switch (queue.status) {
            case 'ACCEPTED_RESTAURANT_CALL': {
                return (
                    <>

                        {currentStatus ? (
                            <>
                                <p className="text-muted">
                                    O restaurante esta aguardando sua chegada.
                                </p>
                                <br/>
                                <br/>
                                <Button variant={'success'} onClick={checkIn}
                                        size={'lg'}
                                        className={'mt-1 mb-2 col-12'}>Cheguei</Button>
                                <span>Você confirmou sua ida as: <strong>{formatDateTime(new Date(queue.lastStatusAt))}</strong>, e tem 5 minutos para comparecer a porta do restaurante.</span>
                            </>
                        ) : (<>
                            <p>
                                Você confirmou sua ida ao restaurante.
                            </p>
                        </>)}
                    </>
                )
            }
            case 'CALLED': {
                return (
                    <>
                        {currentStatus ? (
                            <>
                                <p><strong>{queue.consumer?.name}</strong> chegou sua vez, o restaurante disponibilizou
                                    sua
                                    mesa
                                    para <strong>{queue?.slot}</strong> pessoa{queue?.slot > 1 ? 's' : ''}.</p>
                                <br/>
                                <br/>
                                <Button variant={'success'} onClick={answerCall}
                                        size={'lg'}
                                        className={'mt-3 col-12'}>Confirmar</Button>
                                <Button variant={'danger'} onClick={() => setShowModal(true)}
                                        size={'lg'}
                                        className={'mt-3 mb-2 col-12'}>Recusar</Button>
                                <span>Você foi chamado as: <strong>{formatDateTime(new Date(queue.lastStatusAt))}</strong>, e tem 5 minutos para responder ao chamado, caso não responda perderá seu lugar na fila.</span>
                            </>
                        ) : (<>
                            <p>
                                Sua mesa ficou disponivel e você foi chamado para comparecer ao restaurante.
                            </p>
                        </>)}

                    </>
                )
            }
            case 'IN_QUEUE': {
                return (
                    <>
                        {currentStatus ? (
                            <>
                                <p><strong>{queue.consumer?.name}</strong> enviaremos um SMS
                                    para <strong>{queue.consumer?.phone}</strong> quando sua mesa
                                    para <strong>{queue?.slot}</strong> pessoas estiver disponível.</p>
                                <br/>
                                <br/>
                                <Button variant={'danger'} onClick={() => setShowModal(true)}
                                        size={'lg'}
                                        className={'mt-2 col-12'}>Sair da
                                    fila</Button>
                                <span>Caso não consiga comparecer você pode sair da fila, em caso de não comparecimento enquanto está na fila sua <Link>classificação</Link> será afetada</span>
                            </>
                        ) : (<>
                            <p>
                                O restaurante aceitou sua reserva e te adicionou na fila.
                            </p>
                        </>)}

                    </>
                )
            }
            case 'WAITING_CONFIRMATION': {
                return (
                    <>
                        {currentStatus ? (
                            <>
                                <Alert variant={'warning'}>
                                    <h4>Aguardando confirmação do restaurante</h4>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </Alert>
                                <Button variant={'danger'} onClick={() => setShowModal(true)}
                                        size={'lg'}
                                        className={'mt-3 col-12'}>Cancelar solicitação</Button>
                            </>
                        ) : (<></>)}

                    </>
                )
            }
            case 'CHECK_IN': {
                return (
                    <>
                        {canFeedback ? (
                            <>
                                <p>Excelente! Esperamos que tenha aproveitado o tempo que ficou livre.
                                    Queremos saber o que achou da sua experiência. Se tiver um tempinho, deixe seu
                                    feedback! Sua
                                    opinião é super importante pra gente.</p>
                                <Button variant={'success'}
                                        size={'lg'} onClick={() => setShowSurvey(true)}
                                        className={'mt-3 col-12'}>Avaliar</Button>
                            </>
                        ) : (
                            <>
                                <p>Excelente! Esperamos que tenha aproveitado o tempo que ficou livre.</p>
                            </>
                        )}
                    </>

                )
            }
            default: {
                return (<></>)
            }
        }
    }

    useEffect(() => {
        if (!showSurvey && queue?.merchant?.id) {
            queueApiClient.get('/feedback/available', {
                params: {
                    queueId: queue.id
                }
            }).then(response => {
                setCanFeedback(response.status === 204)
            }).catch(err => {
            })
        }
    }, [showSurvey])

    useEffect(() => {
        if (queue.status === 'CHECK_IN') {
            queueApiClient.get('/feedback/available', {
                params: {
                    queueId: queue.id
                }
            })
                .then(response => {
                    setCanFeedback(response.status === 204)
                }).catch(err => {
            })
        }
    }, [queue]);

    return (
        <Col sm={12} md={10} lg={8}
             className={'mt-3 my-auto mx-auto text-center align-content-center align-items-center align-self-center justify-content-center'}>
            <Card>
                {queue.merchant?.cover ?
                    <Card.Img variant="top" src={queue.merchant?.cover}/> :
                    <></>}
                {queue.merchant?.icon ?
                    <Image roundedCircle={true}
                           className={'bg-white mx-auto p-1'}
                           style={{
                               marginTop: queue.merchant?.cover ? '-100px' : '0',
                               height: "180px",
                               width: "180px",
                               objectFit: "cover"
                           }}
                           src={queue.merchant?.icon}></Image> :
                    <Skeleton circle={true} height={'180px'} width={'180px'}/>
                }
                <Card.Header>
                    <h1>{queue.merchant?.name || <Skeleton count={1}/>}</h1>
                    <span className={'text-muted'} style={{fontSize: "80%"}}>{queue.merchant?.address ||
                        <Skeleton count={1}/>}</span><br/><br/>
                    <span>{queue.merchant?.description || <Skeleton count={1}/>}</span><br/>
                    {queue.merchant?.catalogUrl
                        ? (
                            <Button size={'sm'} className={'mt-2'} color={'info'} href={queue.merchant?.catalogUrl}
                                    target="_blank">
                                Ver cardápio
                            </Button>
                        )
                        : (<></>)}
                </Card.Header>
                {
                    queue.merchant?.name ?
                        getCardBodyByQueueStatus(queue)
                        :
                        <Skeleton count={4} height={50}/>
                }

            </Card>
            <Survey show={showSurvey} stateFunc={setShowSurvey} queue={queue}/>
            <Modal className={"text-center"} show={showModal} centered onHide={() => setShowModal(false)}>
                <Modal.Title className={'mt-2'}>Tem certeza?</Modal.Title>
                <Modal.Body>
                    Pode nos informar o motivo da desistência?
                    <Form.Select onChange={e => setReason(e.target.value)}>
                        <option>Tempo de espera</option>
                        <option>Encontrei outra opção</option>
                        <option>Outro compromisso</option>
                        <option>Outros</option>
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button variant="danger" onClick={quitQueue}>
                        Sim, quero sair
                    </Button>
                    <Button variant="outline-success" onClick={() => setShowModal(false)}>
                        Não, quero continuar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>
    )
}

const Queue = () => {
    const [queue, setQueue] = useState({})
    const [lastQueue, setLastQueue] = useState(false)
    const navigate = useNavigate()
    const [cookies, setCookies] = useCookies(['user'])
    const {queueApiClient} = useQueueApiClient()

    const fetchQueueData = () => {
        queueApiClient.get(`/queue`, {
            params: {
                consumerId: cookies.user
            }
        }).then(response => {
            setQueue(response.data)
            document.title = `Fila de ${response.data.merchant.name}`
        }).catch(reason => {
            queueApiClient.get(`/queue/last`, {
                params: {
                    consumerId: cookies.user
                }
            }).then(response => {
                setQueue(response.data)
                setLastQueue(true)
            }).catch(err => {
                navigate("/")
            })
        })
    }

    useEffect(() => {
        fetchQueueData()
    }, []);

    useEffect(() => {
        if (!lastQueue) {
            const intervalId = setInterval(() => {
                fetchQueueData()
            }, 5000)

            return () => clearInterval(intervalId);
        }
    }, [lastQueue, queue])

    return (
        <>
            <Container>
                <Row>
                    <MerchantCard fetchQueueData={fetchQueueData} last={lastQueue} queueData={queue}></MerchantCard>
                </Row>
                <ToastContainer/>
            </Container>
        </>
    );
}

export default Queue