import {Check2All, HandThumbsUp, HourglassSplit, Send, Telephone, X} from "react-bootstrap-icons";

export const QueueStatus = {
    IN_QUEUE: {
        color: "success",
        label: "Na fila",
        hasButtons: true,
        consumerLabel: "Entrou na fila",
        consumerContent: "Aguardou o restaurante disponibilizar sua mesa.",
        icon: <HourglassSplit/>
    },
    WAITING_CONFIRMATION: {
        color: "warning",
        label: "Aguardando confirmação",
        hasButtons: true,
        consumerLabel: "Aguardando confirmação",
        consumerContent: "O restaurante recebeu sua reserva.",
        icon: <Send/>
    },
    CANCELLED_BY_MERCHANT: {
        color: "danger",
        label: "Removido da fila",
        hasReason: true,
        consumerLabel: "Você foi removido da fila",
        consumerContent: "Você foi removido da fila pelo restaurante.",
        icon: <X/>
    },
    CANCELLED_BY_CONSUMER: {
        color: "danger",
        label: "Saiu da fila",
        hasReason: true,
        consumerLabel: "Você saiu da fila ",
        consumerContent: "Você cancelou e saiu da fila.",
        icon: <X/>
    },
    CALLED: {
        color: "info",
        label: "Chamado",
        hasButtons: true,
        consumerLabel: "Chamado",
        consumerContent: "O restaurante disponibilizou sua mesa e te chamou.",
        icon: <Telephone/>
    },
    ACCEPTED_RESTAURANT_CALL: {
        color: "success",
        label: "A caminho",
        hasButtons: true,
        consumerLabel: "A caminho do restaurante",
        consumerContent: "Você confirmou sua ida ao restaurante.",
        icon: <Check2All/>
    },
    DENIED_RESTAURANT_CALL: {
        color: "danger",
        label: "Recusou a chamada",
        hasReason: true,
        consumerLabel: "Saiu da fila",
        consumerContent: "Você recusou o chamado do restaurante e saiu da fila.",
        icon: <X/>
    },
    CALLED_NOT_ANSWERED: {
        color: "danger",
        label: "Sem resposta",
        consumerLabel: "Removido por falta de resposta",
        consumerContent: "Você nao respondeu ao chamado do restaurante e foi removido da fila apos o prazo de confirmação.",
        icon: <X/>
    },
    NOT_PRESENT: {
        color: "",
        label: "",
        consumerLabel: "",
        consumerContent: "",
        icon: <X/>
    },
    CHECK_IN: {
        color: "success",
        label: "Check in",
        consumerLabel: "Check in",
        consumerContent: "",
        icon: <HandThumbsUp/>
    },
}