import {useAccessToken} from "./useAccessToken";
import {useCookies} from "react-cookie";

const useMerchantId = () => {
    const [cookies] = useCookies(['merchant_id'])
    return {merchantId: cookies.merchant_id}
}

const useQueueApiHeaders = () => {
    const {bearerToken} = useAccessToken()
    const {merchantId} = useMerchantId()

    const merchantAuthHeaderWithIdParam = () => {
        return {
            headers: {
                'Authorization': bearerToken
            },
            params: {
                id: merchantId
            }
        }
    }

    return { merchantAuthHeaderWithIdParam }
}


export default useQueueApiHeaders
