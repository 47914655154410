import {
    Accordion,
    Alert,
    Button,
    Card,
    Col,
    Container,
    FloatingLabel,
    Form,
    Image,
    Modal,
    Row,
    Spinner
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useSearchParams} from "react-router-dom";
import ReactCodeInput from "react-code-input";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useCookies} from "react-cookie";
import {weekDay} from "./MerchantHome";
import {Arrow90degDown, ArrowsFullscreen, BoxArrowUpRight, MenuButton} from "react-bootstrap-icons";
import useQueueApiClient from "../components/useQueueApiClient";

const MerchantCard = (props) => {
    const info = props.info
    const navigate = useNavigate()
    const [phone, setPhone] = useState("")
    const [name, setName] = useState("")
    const [seat, setSeat] = useState("")
    const [showModal, setShowModal] = useState(false)
    const [code, setCode] = useState("")
    const [counter, setCounter] = useState(60);
    const [loading, setLoading] = useState(false)
    const [cookie, setCookie] = useCookies(['user'])
    const {queueApiClient} = useQueueApiClient()

    const maskPhoneAndSaveData = (e) => {
        setPhone(maskPhone(e.target.value))
    }

    const validatePhone = (phone) => {
        const format = /^\(\d{2}\) \d{5}-\d{4}$/;
        return format.test(phone)
    }

    const maskPhone = (phone) => {
        return phone.replace(/\D/g, '')
            .replace(/^(\d)/, '($1')
            .replace(/^(\(\d{2})(\d)/, '$1) $2')
            .replace(/(\d{5})(\d{1,5})/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1');
    }

    const createUser = () => {
        if (validatePhone(phone) && name !== "" && seat !== "")
            queueApiClient.post(`/consumer`, {
                phone: phone,
                name: name
            }).then(response => {
                setCookie('user', response.data.id)
                if (response.data.phoneVerified) {
                    addToQueue(response.data.id)
                } else {
                    queueApiClient.get(`/flag`, {
                        headers: {
                            'x-feature-flag': 'SMS_VERIFICATION_ACCOUNT'
                        }
                    }).then(featureFlagResponse => {
                        if (featureFlagResponse.data.value) {
                            setShowModal(true)
                        }
                        else
                            addToQueue(response.data.id)
                    }).catch(err => {})
                }



            })
    }


    const addToQueue = (consumerId) => {
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/queue`, {
            merchant: {
                id: props.id
            },
            consumer: {
                id: consumerId
            },
            slot: seat
        }).then(res => {
            navigate("/queue")
        }).catch(err => {
            toast.error(err.response.data, {
                theme: "colored"
            })
        })
    }

    const sendCode = () => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/consumer/verify-code`, {}, {
            params: {
                code: code,
                consumer: cookie.user
            }
        }).then(response => {
            console.log(response.data)
            if (response.data.phoneVerified) {
                axios.post(`${process.env.REACT_APP_SERVICE_URL}/queue`, {
                    merchant: {
                        id: props.id
                    },
                    consumer: {
                        id: response.data.id
                    },
                    slot: seat
                }).then(res => {
                    navigate("/queue")
                }).catch(err => {
                    toast.error(err.response.data, {
                        theme: "colored"
                    })
                })

            }
        }).catch(err => {
            setLoading(false)
            toast.error(err.response.data, {
                theme: "colored"
            })
        })
    }

    useEffect(() => {
        if (showModal)
            counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    useEffect(() => {
        if (showModal === false)
            setCounter(60)
    }, [showModal]);

    useEffect(() => {
        if (code.length === 6)
            sendCode()
    }, [code])

    useEffect(() => {
        if (cookie.user !== undefined) {
            axios.get(`${process.env.REACT_APP_SERVICE_URL}/queue`, {
                params: {
                    consumerId: cookie.user
                }
            }).then(response => {
                navigate("/queue")
            }).catch(reason => {

            })
        }
    }, []);

    return (
        <Col sm={12} md={10} lg={8}
             className={'mt-3 mx-auto my-auto text-center align-content-center align-items-center align-self-center justify-content-center'}>
            <Card>
                {info.cover ?
                    <Card.Img variant="top" src={info.cover}/> :
                    <></>}
                {info.icon ?
                    <Image roundedCircle={true}
                           className={'bg-white mx-auto p-1'}
                           style={{
                               marginTop: info.cover ? '-100px' : '0',
                               height: "180px",
                               width: "180px",
                               objectFit: "cover"
                           }}
                           src={info.icon}></Image> :
                    <Skeleton circle={true} height={'180px'} width={'180px'}/>
                }
                <Card.Header>
                    <h1>{info.name || <Skeleton count={1}/>}</h1>
                    <span className={'text-muted'} style={{fontSize: "80%"}}>{info.address ||
                        <Skeleton count={1}/>}</span><br/><br/>
                    <span>{info.description || <Skeleton count={1}/>}</span><br/>
                    {info.catalogUrl
                        ? (
                            <Button size={'sm'} className={'mt-2'} color={'info'} href={info.catalogUrl} target="_blank">
                                Ver cardápio
                            </Button>
                        )
                        : (<></>)}
                </Card.Header>
                {
                    info.name ?
                        (info.isOpen ?
                            <Card.Body>
                                <Form>
                                    <FloatingLabel
                                        label="Seu número de celular"
                                        className="mb-3 col-12">
                                        <Form.Control type="text" onChange={e => maskPhoneAndSaveData(e)}
                                                      value={phone}/>
                                    </FloatingLabel>
                                    <FloatingLabel
                                        label="Qual seu nome?"
                                        className={'col-12 mb-3'}>
                                        <Form.Control type="text" onChange={e => setName(e.target.value)} value={name}/>
                                    </FloatingLabel>
                                    <FloatingLabel
                                        label="Mesa para quantos?"
                                        className={'col-12'}>
                                        <Form.Control type="number" onChange={e => setSeat(e.target.value)}
                                                      value={seat}/>
                                    </FloatingLabel>

                                    <Button variant={'success'} size={'lg'}
                                            onClick={createUser}
                                            className={'mt-3 col-12'}>Entrar na
                                        fila</Button>
                                </Form>
                            </Card.Body>
                            : <Card.Body>
                                <Alert variant={'danger'}>
                                    <span><h5>Restaurante fechado</h5>
                                        <h4>Horario de funcionamento:</h4>
                                        {info.weekDayWorkshift ? (
                                            Object.entries(info.weekDayWorkshift).map(([weekday, val], ix) =>
                                                <Accordion color={'danger'} defaultActiveKey={['0']} alwaysOpen>
                                                    <Accordion.Item eventKey={ix}>
                                                        <Accordion.Header>{weekDay[weekday]}</Accordion.Header>
                                                        <Accordion.Body>
                                                            {Object.entries(val).map(([keyD, valD], ixD) =>
                                                                <p>Entre: {keyD} e {valD.slice(0, -3)}</p>
                                                            )}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            )) : <></>}
                                    </span>
                                </Alert>
                            </Card.Body>)
                        :
                        <Skeleton count={4} height={50}/>
                }
            </Card>
            <Modal className={"text-center"} show={showModal} centered onHide={() => setShowModal(false)}>
                <Modal.Title>Confirmar cadastro</Modal.Title>
                <Modal.Body>
                    Te enviamos um código de confirmação, digite-o abaixo para seguir com sua reserva<br/><br/>
                    <ReactCodeInput onChange={e => setCode(e)} type='number' fields={6} inputMode={'numeric'} name={'sms-confirmation'}/>
                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button variant="success" onClick={sendCode}>
                        Confirmar {loading ? <Spinner></Spinner> : <></>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>
    )
}

const JoinQueue = () => {
    const [merchantInfo, setMerchantInfo] = useState({})
    const [searchParams] = useSearchParams()
    const merchantId = searchParams.get('merchant')

    const fetchMerchantData = () => {
        axios.get(`${process.env.REACT_APP_SERVICE_URL}/merchant`, {
            params: {
                id: merchantId
            }
        }).then(response => {
            setMerchantInfo(response.data)
            document.title = `Entrar na fila de ${response.data.name}`
        })
    }

    useEffect(() => {
        fetchMerchantData()
    }, []);

    return (
        <>
            <Container>
                <Row>
                    <MerchantCard id={merchantId} info={merchantInfo}></MerchantCard>
                </Row>
                <ToastContainer/>
            </Container>
        </>
    );
}

export default JoinQueue