import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FloatingLabel,
    Form,
    Navbar,
    NavDropdown
} from "react-bootstrap";
import {useEffect, useState} from "react";
import axios from "axios";
import {useCookies} from "react-cookie";
import {useNavigate} from "react-router-dom";
import {useJwt} from "react-jwt";
import {toast, ToastContainer} from "react-toastify";
import {ArrowLeft} from "react-bootstrap-icons";

const MerchantLogin = () => {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [cookies, setCookie, removeCookie] = useCookies(['access_token'])
    const [token, setToken] = useState()
    const navigate = useNavigate()
    const { decodedToken, isExpired, reEvaluateToken  } = useJwt(cookies.access_token);
    const accessAccount = () => {
        removeCookie('access_token')
        axios.post(`${process.env.REACT_APP_SERVICE_URL}/account/login`, {}, {
            headers: {
                'x-account-user': user,
                'x-account-password': btoa(password)
            }
        }).then(response => {
            const token =  response.data.accessToken;
            reEvaluateToken(token)
            setToken(token)
            setCookie('access_token', token)
            setCookie('email', user)
        }).catch(err => {
            toast.error("Erro ao tentar logar", {
                theme: "colored"
            })
        })
    }

    useEffect(() => {
        if(isExpired) {
            navigate('/login/merchant')
        }
    }, [decodedToken])

    useEffect(() => {
        if(token) {
            setCookie('merchant_id', decodedToken.merchant)
            navigate("/merchant")
        }
    }, [token]);

    return (
        <Container>
            <Navbar>
                <Container>

                    <Navbar.Brand href={'/'}>
                        <img
                            alt=""
                            src="/kiwi-fruit.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        Ki-wi</Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Text className="justify-content-center">
                        Area do estabelecimento
                    </Navbar.Text>
                </Container>
            </Navbar>
            <Col className={'mx-auto'} lg={8} md={10} sm={12}>
                <Card className={'mt-5'}>
                    <CardHeader className={'text-center'}>
                        Acessar portal
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FloatingLabel
                                label="E-mail de acesso"
                                className="mb-3 col-12">
                                <Form.Control type="text" onChange={(e) => setUser(e.target.value)}/>
                            </FloatingLabel>
                            <FloatingLabel
                                label="Senha"
                                className={'col-12 mb-3'}>
                                <Form.Control type="password" onChange={(e) => setPassword(e.target.value)}/>
                            </FloatingLabel>
                            <Button variant={'success'} size={'lg'} onClick={accessAccount}
                                    className={'mt-3 col-12'}>Entrar</Button>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
            <ToastContainer/>
        </Container>
    )
}

export default MerchantLogin