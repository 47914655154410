import {useCookies} from "react-cookie";

export const useAccessToken = () => {

    const [cookies, removeCookies, setCookies] = useCookies(['access_token']);

    const cleanToken = () => {
        removeCookies('access_token')
    }

    const setToken = (token) => {
        cleanToken()
        setCookies('access_token', token)
    }

    return {
        token: cookies.access_token,
        bearerToken: `Bearer ${cookies.access_token}`,
        cleanToken,
        setToken
    };
};
