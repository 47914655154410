import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import JoinQueue from "./pages/JoinQueue";
import Home from "./pages/Home";
import "bootstrap/dist/css/bootstrap.css"
import 'react-loading-skeleton/dist/skeleton.css'
import Queue from "./pages/Queue";
import MerchantQueue from "./pages/MerchantQueue";
import MerchantHome from "./pages/MerchantHome";
import 'react-toastify/dist/ReactToastify.css';
import MerchantLogin from "./pages/MerchantLogin";
import MerchantQRCode from "./pages/MerchantQRCode";
import Legal from "./pages/Legal";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>
    },
    {
        path: "/legal",
        element: <Legal/>
    },
    {
        path: "/queue/join",
        element: <JoinQueue/>,
    },
    {
        path: "/queue",
        element: <Queue/>,
    },
    {
        path: "/merchant/qr",
        element: <MerchantQRCode/>
    },
    {
        path: "/merchant",
        element: <MerchantHome/>
    },
    {
        path: "/login/merchant",
        element: <MerchantLogin/>,
    },
    {
        path: "/merchant/queue",
        element: <MerchantQueue/>,
    }
]);

function App() {
    return (
            <RouterProvider router={router}/>
    );
}

export default App;
