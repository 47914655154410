import {
    Accordion,
    Badge,
    Button,
    Card,
    Col,
    Container,
    Form,
    Image,
    InputGroup,
    Modal,
    Navbar,
    NavDropdown,
    Row,
    Table
} from "react-bootstrap";
import {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import {GraphUpArrow, LightningChargeFill, PlusLg, QrCodeScan, Trash} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {toast, ToastContainer} from "react-toastify";
import useQueueApiClient from "../components/useQueueApiClient";
import useQueueApiHeaders from "../components/MerchantController";
import {useAccessToken} from "../components/useAccessToken";
import {formatDate, formatStringDate} from "../components/utils/Utils";
import {QueueStatus} from "../components/utils/QueueStatus";

export const weekDay = {
    "monday": "Segunda",
    "tuesday": "Terça",
    "wednesday": "Quarta",
    "thursday": "Quinta",
    "friday": "Sexta",
    "saturday": "Sabado",
    "sunday": "Domingo"
}
const MerchantHome = (props) => {

    const [merchantData, setMerchantData] = useState({})
    const [key, setKey] = useState(Math.random)
    const navigate = useNavigate()
    const {queueApiClient} = useQueueApiClient()
    const {token, cleanToken} = useAccessToken()
    const {merchantAuthHeaderWithIdParam} = useQueueApiHeaders()
    const [cookies, removeCookies] = useCookies(['email'])

    const updateMerchantData = () => {
        queueApiClient.patch(`/merchant`,
            merchantData,
            merchantAuthHeaderWithIdParam()
        ).then(response => {
            setMerchantData(response.data)
        })
    }

    const fetchMerchantData = () => {
        queueApiClient.get('/merchant/full',
            merchantAuthHeaderWithIdParam()
        ).then(response => {
            setMerchantData(response.data)
            document.title = `Home de ${response.data.name}`
        }).catch(err => {
            toast.error("Erro ao buscar dados do estabelecimento", {
                theme: "colored"
            })
        })
    }

    const validateAccessToken = () => {
        queueApiClient.get('/account/validate', {
            headers: {
                'x-access-token': token
            }
        }).then(response => {
        }).catch(err => {
            toast.error("Sessao perdida", {
                theme: "colored"
            })
            cleanToken()
            navigate("/login/merchant")
        })
    }

    useEffect(() => {
        validateAccessToken()
        if (token) {
            fetchMerchantData()
        }
    }, []);

    useEffect(() => {
        setKey(Math.random)
    }, [merchantData]);

    const logout = () => {
        cleanToken()
        removeCookies('merchant_id')
        removeCookies('email')
        navigate("/login/merchant")
    }

    return (
        <Container>
            <Row>
                <Col sm={12} md={12} lg={12}
                     className={'mt-3 text-center align-content-center align-items-center align-self-center justify-content-center'}>
                    <Navbar>
                        <Container>
                            <Navbar.Brand>
                                <img
                                    alt=""
                                    src="/kiwi-fruit.svg"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                />{' '}
                                Ki-wi</Navbar.Brand>
                            <Navbar.Toggle/>
                            <Navbar.Collapse className="justify-content-end">
                                <NavDropdown title={cookies.email} id="basic-nav-dropdown">
                                    <NavDropdown.Item className={'text-danger'} onClick={logout}>
                                        Desconectar
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                    <MerchantCard fetch={fetchMerchantData} merchantData={merchantData} key={key}></MerchantCard>
                < /Col>
            </Row>
            <ToastContainer/>
        </Container>
    )
}

let activeKeyState = null;

const MerchantCard = (props) => {
    const [newWorkShift, setNewWorkShift] = useState({
        open: undefined,
        close: undefined
    })
    const {bearerToken} = useAccessToken()
    const {queueApiClient} = useQueueApiClient()
    const merchant = props.merchantData
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [removeConsumersOnClose, setRemoveConsumersOnClose] = useState(false)
    const [reason, setReason] = useState("Falta de insumos")
    const [merchantWorkShift, setMerchantWorkShift] = useState(merchant.workShift)
    const [endDate, setEndDate] = useState(formatDate(new Date()))
    const [startDate, setStartDate] = useState(formatDate(new Date()))
    const [merchantReport, setMerchantReport] = useState([
        {
            "status": "CANCELLED_BY_CONSUMER",
            "total": '-'
        },
        {
            "status": "CHECK_IN",
            "total": '-'
        },
        {
            "status": "DENIED_RESTAURANT_CALL",
            "total": '-'
        },
        {
            "status": "CALLED_NOT_ANSWERED",
            "total": '-'
        },
        {
            "status": "CANCELLED_BY_MERCHANT",
            "total": '-'
        }
    ])

    const setActiveKeyState = (state) => {
        activeKeyState = state
    }

    const updateDate = (type, value) => {
        console.log(value)
        if (type === 'start') {
            setStartDate(formatStringDate(value))
        }

        if (type === 'end') {
            setEndDate(formatStringDate(value))
        }
    }

    const addNewWorkshift = () => {
        queueApiClient.patch(`/merchant/workshift`, {
            weekday: newWorkShift.weekday,
            workshifts: [
                {
                    open: newWorkShift.open,
                    close: newWorkShift.close
                }
            ]
        }, {
            headers: {
                Authorization: bearerToken
            },
            params: {
                id: merchant.id
            }
        }).then(response => {
            props.fetch()
        })
    }

    const deleteWorkShift = (workshift) => {
        const parts = workshift.split('-');
        queueApiClient.delete(`/merchant/workshift`, {
            headers: {
                Authorization: bearerToken
            },
            data: {
                weekday: parts[0],
                workshifts: [
                    {
                        open: parts[1],
                        close: parts[2]
                    }
                ]
            },
            params: {
                workShift: workshift,
                id: merchant.id
            }
        }).then(response => {
                setActiveKeyState("0")
                props.fetch()
            }
        )
    }

    const toggleAutomation = (automation) => {
        queueApiClient.patch(`/merchant/toggle/automation`, {}, {
            headers: {
                Authorization: bearerToken
            },
            params: {
                automation: automation,
                id: merchant.id
            }
        }).then(response => {
            props.fetch()
        })
    }

    const openMerchant = () => {
        queueApiClient.patch(`/merchant/toggle/open`, {}, {
            headers: {
                Authorization: bearerToken
            },
            params: {
                id: merchant.id
            }
        }).then(response => {
            if (removeConsumersOnClose && merchant.open) {
                queueApiClient.patch("/queue/purge", {}, {
                    headers: {
                        Authorization: bearerToken
                    },
                    params: {
                        id: merchant.id,
                        reason: reason,
                    }
                }).then(r => {
                    toast.info('A fila foi desfeita e todos foram dispensados', {theme: "colored"})
                }).catch(err => {
                    toast.error('Erro ao desfazer a fila', {theme: "colored"})
                })
            }
            setShowModal(false)
            props.fetch()
        })
    }

    const fetchMerchantReport = () => {
        queueApiClient.get('/queue/report', {
                headers: {
                    Authorization: bearerToken
                },
                params: {
                    id: 'f0ae9bd4-2490-4158-a583-ae4b843fcdcc',
                    startDate: startDate,
                    endDate: endDate
                }
            }
        ).then(response => {
            setMerchantReport(response.data)
        }).catch(err => {
            toast.error("Erro ao buscar dados do estabelecimento", {
                theme: "colored"
            })
        })
    }

    const redirectToQueue = () => {
        navigate('/merchant/queue')
    }

    const openReport = () => {
        setReportModal(true)
    }

    const updateMerchant = (event) => {
        console.log(event.target.value)
    }

    let total=0;

    return (
        <Col sm={12} md={12} lg={12}
             className={'mt-3 text-center align-content-center align-items-center align-self-center justify-content-center'}>
            <Card>
                {merchant.cover ?
                    <Card.Img variant="top" src={merchant.cover}/> :
                    <></>}
                {merchant.icon ?
                    <Image roundedCircle={true}
                           className={'bg-white mx-auto p-1'}
                           style={{
                               marginTop: merchant.cover ? '-100px' : '0',
                               height: "180px",
                               width: "180px",
                               objectFit: "cover"
                           }}
                           src={merchant.icon}></Image> :
                    <Skeleton circle={true} height={'180px'} width={'180px'}/>
                }
                <Card.Body>
                    <h1>{merchant.name
                        || <Skeleton count={1}/>}</h1>
                    <span className={'text-muted'} style={{fontSize: "80%"}}>{merchant.address
                        || <Skeleton count={1}/>}</span><br/><br/>
                    <span>{
                        merchant.description
                        || <Skeleton count={1}/>}</span><br/><br/>
                    <span>
                        <Button size={'sm'}
                                onClick={() => navigate("/merchant/qr")}>Gerar QRCode <QrCodeScan/> </Button>

                        <Button size={'sm'} className={'mx-2'}
                                onClick={openReport}>Relatório <GraphUpArrow/> </Button>
                    </span>
                </Card.Body>
                <Card.Footer>
                    <h6>
                        Status da loja:&nbsp;
                        <Badge bg={merchant.open ? 'success' : 'danger'}>{merchant.open ? "Aberta" : "Fechada"}</Badge>
                        {merchant.open !== merchant.isOpen
                            ? <> &nbsp;<Badge bg={'primary'}><LightningChargeFill/>&nbsp;Auto abertura</Badge> </>
                            : <></>}
                    </h6>

                    <Row className={'mb-2 text-center'}>
                        <Button className={'col-5 mt-4 mx-auto'} onClick={() => {
                            if (merchant.open)
                                setShowModal(!showModal)
                            else
                                openMerchant()
                        }}
                                variant={merchant.open ? 'danger' : 'success'}>
                            {merchant.open ? "Fechar loja" : "Abrir loja"}</Button>

                        <Button className={'col-5 mt-4 mx-auto'}
                                onClick={redirectToQueue}
                                variant={merchant.open ? 'success' : 'outline-success'}>Acessar fila</Button>
                    </Row>
                </Card.Footer>
            </Card>

            <h2 className={'mt-3'}>Configurações</h2>
            <Accordion defaultActiveKey={activeKeyState}>
                <Accordion.Item eventKey="0" onClick={e => setActiveKeyState("0")}>
                    <Accordion.Header>Horarios de funcionamento</Accordion.Header>
                    <Accordion.Body>
                        {merchant.weekDayWorkshift ? (
                            Object.entries(merchant.weekDayWorkshift).map(([weekday, val], ix) =>
                                <Accordion defaultActiveKey={['0']} alwaysOpen>
                                    <Accordion.Item eventKey={ix}>
                                        <Accordion.Header>{weekDay[weekday]}</Accordion.Header>
                                        <Accordion.Body>
                                            {Object.entries(val).map(([keyD, valD], ixD) =>
                                                <Row className={'mt-2'}>
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Text>Abre</InputGroup.Text>
                                                        <Form.Control defaultValue={keyD} size={"sm"} type={'text'}/>
                                                        <InputGroup.Text>Fecha</InputGroup.Text>
                                                        <Form.Control defaultValue={valD} size={"sm"} type={'text'}/>
                                                        <Button variant={'danger'}
                                                                onClick={e => deleteWorkShift(`${weekday}-${keyD}-${valD}`)}>
                                                            <Trash></Trash>
                                                        </Button>
                                                    </InputGroup>
                                                </Row>
                                            )}
                                            <h5 className={'mt-3'}>Adicionar intervalo de funcionamento</h5>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Text>Abre</InputGroup.Text>
                                                <Form.Control size={"sm"} type={'time'} onChange={e => setNewWorkShift({
                                                    open: e.target.value,
                                                    close: newWorkShift.close,
                                                    weekday: weekday
                                                })}/>
                                                <InputGroup.Text>Fecha</InputGroup.Text>
                                                <Form.Control size={"sm"} type={'time'} onChange={e => setNewWorkShift({
                                                    open: newWorkShift.open,
                                                    close: e.target.value,
                                                    weekday: weekday
                                                })}/>

                                                <Button variant={'success'} onClick={addNewWorkshift}>
                                                    <PlusLg></PlusLg>
                                                </Button>
                                            </InputGroup>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )) : <></>}

                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" onClick={e => setActiveKeyState("1")}>
                    <Accordion.Header>Automação</Accordion.Header>
                    <Accordion.Body>
                        <Row>
                            <Col className={'mt-2'} sm={12}>
                            <span>
                                <Form.Switch
                                    reverse
                                    defaultChecked={merchant.autoAcceptConsumer}
                                    onClick={e => toggleAutomation('AUTO_ACCEPT_CONSUMER')}
                                    className={'text-start'}
                                    label="Aceitar cliente automaticamente"
                                />
                            </span>
                            </Col>
                            <Col className={'mt-2'} sm={12}>
                            <span>
                                <Form.Switch
                                    reverse
                                    defaultChecked={merchant.autoQueue}
                                    onClick={e => toggleAutomation('AUTO_QUEUE')}
                                    className={'text-start'}
                                    label="Abrir a fila automaticamente"
                                />
                            </span>
                            </Col>
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Modal className={"text-center"} show={showModal} centered onHide={() => setShowModal(false)}>
                <Modal.Title className={'mt-3'}>Fechar loja?</Modal.Title>
                <Modal.Body>
                    Sua loja será marcada como fechada e não receberá mais solicitações de consumidores.<br/><br/>
                    {merchant.autoQueue ? (
                        <p>A automação de <strong>Abrir a fila
                            automaticamente</strong> será <strong>desabilitada</strong> caso
                            confirme o fechamento da loja. Para reativá-la, basta ir à seção de automações</p>
                    ) : (<> </>)}

                    <strong>
                        <Form.Check
                            className={'text-danger strong'}
                            inline
                            defaultChecked={removeConsumersOnClose}
                            onChange={() => setRemoveConsumersOnClose(!removeConsumersOnClose)}
                            label="Remover todos os consumidores da fila"
                            name="removeConsumers"
                            type={'checkbox'}
                            id={`inline-checkbox-1`}
                        />
                    </strong>

                    {removeConsumersOnClose ? (
                        <Form.Select onChange={e => setReason(e.target.value)}>
                            <option>Falta de insumos</option>
                            <option>Problemas internos</option>
                            <option>Outros</option>
                        </Form.Select>
                    ) : (<></>)}
                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button variant="danger" onClick={openMerchant}>
                        Fechar loja
                    </Button>
                    <Button variant="outline-dark" onClick={() => setShowModal(false)}>
                        Manter aberta
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal className={"text-center"} show={reportModal} centered onHide={() => setReportModal(false)}>
                <Modal.Title className={'mt-3'}>Relatório</Modal.Title>
                <Modal.Body>
                    <h6>Buscar informações</h6>
                    <Row className={'mt-2'}>
                        <Col sm={12} md={12} lg={12}>
                            De: <input className={'form-control'} defaultValue={startDate}
                                                      onChange={(e) => updateDate('start', e.target.value)}
                                                      type={"date"}/>
                            <br/>
                            Até: <input className={'form-control'} defaultValue={endDate}
                                                   onChange={(e) => updateDate('end', e.target.value)}
                                                   type={"date"}/>
                            <Button className={'mt-3 mb-3'} onClick={fetchMerchantReport} variant={'primary'}>Buscar</Button>
                        </Col>
                    </Row>
                    <Table striped bordered>
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th>Quantidade</th>
                        </tr>
                        </thead>

                        <tbody>
                        {merchantReport.map(mr => {
                            total += mr.total
                            return (
                                <tr>
                                    <td>{QueueStatus[mr.status].label}</td>
                                    <td>{mr.total}</td>
                                </tr>
                            )
                        })}

                        <tr>
                            <td><strong>Todos os status</strong></td>
                            <td><strong>{total}</strong></td>
                        </tr>
                        </tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer className={'justify-content-center'}>
                    <Button variant={'danger'} onClick={e => setReportModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </Col>
    )
}

export default MerchantHome