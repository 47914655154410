import {Card, CardBody, Col, Container, Nav, Navbar, NavLink, Row, Tabs, Tab} from "react-bootstrap";
import {ToastContainer} from "react-toastify";

const Home = () => {
    document.title = "Ki-wi"
    const glassCardBg = {
        borderBottomRightRadius: "5px",
        borderBottomLeftRadius: "5px",
        background: 'rgba(255, 255, 255, 0.16)',
        //boxShadow: '0 0px 2px rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(20px)',
        '-webkit-backdrop-filter': 'blur(20px)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.5)'
    }

    const glassCardBg2 = {
        borderRadius: "20px",
        background: 'linear-gradient(55deg, rgba(243,243,243,1) 0%, rgba(243,243,243,1) 52%, rgba(245,204,255,1) 73%, rgba(130,217,254,1) 90%, rgba(42,225,231,1) 100%)',
        //background: 'rgb(243,243,243)',
    }

    return (
        <>
            <Navbar sticky={"top"} fixed={"top"} style={glassCardBg}>
                <Container>
                    <Navbar className="">
                        <Container>
                            <Navbar.Brand href={"/"}>
                                <img
                                    alt=""
                                    src="/kiwi-fruit.svg"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                />{' '}
                                Ki-wi
                            </Navbar.Brand>
                            <Nav className="me-auto justify-content-end">
                                <NavLink href="/login/merchant">Entrar</NavLink>
                            </Nav>
                        </Container>

                    </Navbar>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                </Container>
            </Navbar>
            <h1 className={'fw-bolder mt-5 display-1 text-center grad-text'}>JURÍDICO</h1>

            <section className={'mt-5'} id={"politica-de-privacidade"}>
                <Container>
                    <Tabs
                        defaultActiveKey="1"
                        id="justify-tab-example"
                        className="mb-3"
                        justify>
                        <Tab eventKey={1} title="Política de privacidade">
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <Card style={{
                                        background: 'rgb(243,243,243)',
                                        borderRadius: '20px'
                                    }}>
                                        <Container>
                                            <CardBody>
                                                <h1 className={'fw-bolder display-1 text-center'}>Política de Privacidade</h1>

                                                <h2>1. Introdução</h2>
                                                <p>A Ki-Wi valoriza a privacidade dos seus usuários e está comprometida em
                                                    proteger as informações pessoais coletadas durante o uso da nossa
                                                    plataforma. Esta Política de Privacidade descreve como coletamos, usamos,
                                                    armazenamos e protegemos suas informações, em conformidade com o Marco Civil
                                                    da Internet (Lei nº 12.965/2014) e demais legislações aplicáveis no
                                                    Brasil.</p>

                                                <h2>2. Coleta de Informações</h2>

                                                <h3>2.1. Informações Fornecidas pelo Usuário</h3>
                                                <p>Ao se registrar na plataforma Ki-Wi, podemos solicitar que você forneça
                                                    informações pessoais, como nome, endereço de e-mail, telefone e outras
                                                    informações de contato necessárias para criar e gerenciar sua conta.</p>

                                                <h3>2.2. Informações Coletadas Automaticamente</h3>
                                                <p>Durante o uso da nossa plataforma, coletamos automaticamente determinadas
                                                    informações técnicas e de navegação, como endereço IP, tipo de navegador,
                                                    páginas acessadas, tempo de permanência na plataforma, entre outros dados de
                                                    interação.</p>

                                                <h3>2.3. Cookies e Tecnologias Semelhantes</h3>
                                                <p>Utilizamos cookies e outras tecnologias de rastreamento para melhorar a
                                                    experiência do usuário, personalizar conteúdos e anúncios, e analisar o
                                                    tráfego da plataforma. Você pode gerenciar as configurações de cookies
                                                    diretamente no seu navegador.</p>

                                                <h2>3. Uso das Informações</h2>

                                                <h3>3.1. Finalidade do Tratamento</h3>
                                                <p>As informações coletadas são utilizadas para:</p>
                                                <ul>
                                                    <li>Operar e melhorar a plataforma Ki-Wi;</li>
                                                    <li>Personalizar a experiência do usuário;</li>
                                                    <li>Comunicar-se com você, respondendo a solicitações e fornecendo
                                                        suporte;
                                                    </li>
                                                    <li>Cumprir obrigações legais e regulatórias.</li>
                                                </ul>

                                                <h3>3.2. Compartilhamento de Informações</h3>
                                                <p>Não compartilhamos suas informações pessoais com terceiros, exceto:</p>
                                                <ul>
                                                    <li>Quando necessário para o funcionamento da plataforma, como serviços de
                                                        hospedagem e análise;
                                                    </li>
                                                    <li>Em cumprimento de obrigação legal ou ordem judicial;</li>
                                                    <li>Com seu consentimento explícito.</li>
                                                </ul>

                                                <h2>4. Armazenamento e Segurança das Informações</h2>

                                                <h3>4.1. Proteção de Dados</h3>
                                                <p>A Ki-Wi adota medidas de segurança técnicas e organizacionais apropriadas
                                                    para proteger suas informações contra acesso não autorizado, perda,
                                                    destruição ou alteração.</p>

                                                <h3>4.2. Prazo de Retenção</h3>
                                                <p>As informações pessoais serão armazenadas pelo tempo necessário para cumprir
                                                    as finalidades descritas nesta Política de Privacidade ou conforme exigido
                                                    por lei. Após esse período, os dados serão excluídos ou anonimizados.</p>

                                                <h2>5. Direitos dos Usuários</h2>
                                                <p>De acordo com o Marco Civil da Internet, você tem os seguintes direitos:</p>
                                                <ul>
                                                    <li><strong>Acesso:</strong> Solicitar informações sobre os dados que
                                                        coletamos e armazenamos sobre você.
                                                    </li>
                                                    <li><strong>Correção:</strong> Pedir a correção de dados pessoais
                                                        imprecisos, incompletos ou desatualizados.
                                                    </li>
                                                    <li><strong>Exclusão:</strong> Solicitar a exclusão dos seus dados pessoais,
                                                        exceto quando a manutenção for necessária para cumprimento de obrigações
                                                        legais.
                                                    </li>
                                                    <li><strong>Revogação do Consentimento:</strong> Revogar o consentimento
                                                        dado para o tratamento dos dados, quando aplicável.
                                                    </li>
                                                </ul>
                                                <p>Para exercer esses direitos, entre em contato conosco pelo e-mail <a
                                                    href="mailto:contato@ki-wi.app">contato@ki-wi.app</a>.</p>

                                                <h2>6. Alterações nesta Política de Privacidade</h2>
                                                <p>A Ki-Wi pode atualizar esta Política de Privacidade periodicamente.
                                                    Notificaremos você sobre mudanças significativas por meio da nossa
                                                    plataforma ou por outros canais apropriados. Recomendamos que você revise
                                                    esta política regularmente.</p>

                                                <h2>7. Contato</h2>
                                                <p>Se você tiver dúvidas, preocupações ou solicitações relacionadas a esta
                                                    Política de Privacidade, entre em contato conosco em <a
                                                        href="mailto:contato@ki-wi.app">contato@ki-wi.app</a>.</p>
                                            </CardBody>
                                        </Container>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey={2} title="Termos de uso">
                            <Row>
                                <Col sm={12} md={12} lg={12}>
                                    <Card style={{
                                        background: 'rgb(243,243,243)',
                                        borderRadius: '20px'
                                    }}>
                                        <Container>
                                            <CardBody>
                                                <h1 className={'display-1 fw-bolder text-center'}>Termos de Uso</h1>

                                                <h2>1. Aceitação dos Termos</h2>

                                                Ao acessar e utilizar a plataforma Ki-Wi, você concorda em cumprir e estar
                                                sujeito aos
                                                Termos de Uso a seguir. Caso não concorde com qualquer parte destes termos, não
                                                deverá
                                                utilizar a plataforma.

                                                <h2>2. Uso da Plataforma</h2>

                                                A plataforma Ki-Wi destina-se a gerenciar filas e otimizar o atendimento ao
                                                cliente em
                                                estabelecimentos comerciais e outros ambientes. Você concorda em usar a
                                                plataforma
                                                apenas para fins legais e de acordo com todas as leis e regulamentações
                                                aplicáveis. É
                                                proibido utilizar a plataforma para fins fraudulentos ou para a prática de
                                                qualquer
                                                atividade ilegal.

                                                <h2>3. Registro e Segurança da Conta</h2>

                                                Para utilizar a plataforma Ki-Wi, você pode ser solicitado a criar uma conta.
                                                Você é responsável por manter a confidencialidade das informações da sua conta,
                                                incluindo sua senha, e por todas as atividades que ocorrerem sob sua conta. Você
                                                concorda em notificar imediatamente a Ki-Wi sobre qualquer uso não autorizado de
                                                sua conta ou qualquer outra violação de segurança.

                                                <h2> 4. Propriedade Intelectual</h2>

                                                Todos os direitos de propriedade intelectual relacionados à plataforma
                                                Ki-Wi, incluindo, sem limitação, software, conteúdo, design, gráficos e
                                                logotipos, são de propriedade exclusiva da Ki-Wi ou de seus licenciadores.
                                                Você não pode reproduzir, distribuir, modificar ou criar trabalhos derivados
                                                de qualquer material contido na plataforma sem o consentimento prévio por
                                                escrito da Ki-Wi.

                                                <h2>5. Limitação de Responsabilidade</h2>

                                                A Ki-Wi não se responsabiliza por qualquer dano direto, indireto,
                                                incidental, especial, consequente ou punitivo resultante do uso ou da
                                                incapacidade de usar a plataforma, mesmo que a Ki-Wi tenha sido avisada da
                                                possibilidade de tais danos. Isso inclui, sem limitação, danos por perda de
                                                lucros, dados ou outras perdas intangíveis.

                                                <h2>6. Modificações nos Termos</h2>

                                                A Ki-Wi reserva-se o direito de modificar estes Termos de Uso a qualquer
                                                momento. As alterações entrarão em vigor imediatamente após a publicação dos
                                                Termos de Uso revisados na plataforma. Seu uso continuado da plataforma após
                                                a publicação constitui sua aceitação das alterações.

                                                <h2>7. Encerramento</h2>

                                                A Ki-Wi reserva-se o direito de encerrar ou suspender o acesso à plataforma
                                                a qualquer momento, sem aviso prévio, por qualquer motivo, incluindo, sem
                                                limitação, a violação destes Termos de Uso.

                                                <h2>8. Lei Aplicável</h2>

                                                Estes Termos de Uso serão regidos e interpretados de acordo com as leis do
                                                país em que a Ki-Wi está registrada, sem dar efeito a qualquer princípio de
                                                conflitos de leis.

                                                <h2>9. Contato</h2>

                                                Caso tenha dúvidas sobre estes Termos de Uso, entre em contato conosco pelo
                                                e-mail: <a href={'mailTo:contato@ki-wi.app'}>contato@ki-wi.app</a>.
                                            </CardBody>
                                        </Container>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>

                </Container>
            </section>

            <footer style={{
                background: "#242424",
                minHeight: '6vh'
            }} id={'footer'}>
                <div className={'text-white text-center mt-5'}>
                    <p>
                        Entre em contato: <a href={'mailTo:contato@ki-wi.app'}>contato@ki-wi.app</a>
                        <br/>
                        &copy; {new Date(Date.now()).getFullYear()} Ki-wi
                    </p>
                </div>
            </footer>

            <ToastContainer/>
        </>
    )
}

export default Home;