import {Button, Col, Container, Offcanvas, Row} from "react-bootstrap";
import StarRatings from "react-star-ratings/build/star-ratings";
import {useState} from "react";
import useQueueApiClient from "./useQueueApiClient";
import {toast} from "react-toastify";

const Survey = (props) => {
    const queue = props.queue
    const show = props.show
    const setShow = props.stateFunc

    const [firstQuestion, setFirstQuestion] = useState(0)
    const [secondQuestion, setSecondQuestion] = useState(0)
    const [thirdQuestion, setThirdQuestion] = useState(0)
    const {queueApiClient} = useQueueApiClient()

    const sendFeedback = () => {
        queueApiClient.post('/feedback/v1/check-in', {
            firstRating: firstQuestion,
            secondRating: secondQuestion,
            thirdRating: thirdQuestion,
            queueId: queue.id
        }).then(response => {
            toast.success('Feedback enviado com sucesso', {theme:"colored"})
            setShow(false)
        }).catch(err => {
            toast.error("Erro ao enviar feedback", {theme: "colored"})
        })
    }

    return (
        <>
            <Offcanvas show={show} className={'h-auto'} onHide={() => setShow(false)} placement={'bottom'}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className={'text-center'}>
                        Pesquisa de satisfação
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container className={'text-center'}>
                        <Row>
                            <Col className={'mb-3'} lg={12} md={12} sm={12}>
                                <h5>Eficiência do aplicativo</h5>
                                <p className={'text-muted'}>Como você avalia a sua experiência ao utilizar o nosso
                                    aplicativo de fila digital em comparação com outras formas
                                    de espera tradicionais (por exemplo, esperar em fila física)?</p>

                                    <StarRatings
                                        starRatedColor={'green'}
                                        starHoverColor={'green'}
                                        rating={firstQuestion}
                                        changeRating={setFirstQuestion}
                                        numberOfStars={5}
                                        starDimension={45}
                                        name='rating'
                                    />
                            </Col>
                            <hr/>
                            <Col className={'mb-3'} lg={12} md={12} sm={12}>
                                <h5>Satisfação com a flexibilidade de espera</h5>
                                <p className={'text-muted'}>Como você se sentiu em relação à liberdade de sair e
                                    fazer outras atividades enquanto aguardava?</p>
                                <StarRatings
                                    starRatedColor={'green'}
                                    starHoverColor={'green'}
                                    rating={secondQuestion}
                                    changeRating={setSecondQuestion}
                                    numberOfStars={5}
                                    starDimension={45}
                                    name='rating'
                                />
                            </Col>
                            <hr/>
                            <Col className={'mb-3'} lg={12} md={12} sm={12}>
                                <h5>Qualidade do atendimento no restaurante</h5>
                                <p className={'text-muted'}>Como você classificaria a qualidade do atendimento que
                                    recebeu do restaurante ao utilizar o aplicativo?</p>
                                <StarRatings
                                    starRatedColor={'green'}
                                    starHoverColor={'green'}
                                    rating={thirdQuestion}
                                    changeRating={setThirdQuestion}
                                    numberOfStars={5}
                                    starDimension={45}
                                    name='rating'
                                />
                            </Col>

                            <Button className={'mt-3 mb-3'} variant={'success'} onClick={sendFeedback}>
                                Concluir
                            </Button>
                        </Row>
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Survey